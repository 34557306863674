.content {
    @include margin-padding(null, 40px 0);
    .content-header {
        padding-bottom: 40px;
        @include respond-below(custom767) {
            padding-bottom: 20px;
        }
        h4 {
            font-size: $__font__size__32;
            font-weight: $__bold;
            color: $__black;
            margin-bottom: 0;
            @include respond-below(custom991) {
                font-size: $__font__size__28;
            }
            @include respond-below(custom767) {
                font-size: $__font__size__26;
            }
        }
    }
    .content-settings-header {
        padding-bottom: 15px;
    }
    &.dashboard-content {
        padding-bottom: 16px;
    }
}

.dashboard_container{
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    @include respond-below(custom768) {
        width: 90%;
    }
}
.user-quote-card{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
    color: #000;
    font-size: 14px;

    .user-quote-car-details{
        display: flex;
        gap: 20px;
        width: 30%;

        @include respond-below(custom1024) {
           flex-direction: column;  
        }
        
        @include respond-below(sm) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .user-quote-img{
        width: fit-content;

        @include respond-below(custom1024) {
            width: 100%;
        }

        @include respond-below(sm) {
            width:fit-content
        }
    }

    .user-quote-name{
        overflow-wrap: anywhere;
    }

    .user-quote-fleet-for-smalL-screen{
        display: none;

        @include respond-below(custom1024) {
            display: block;
        }
    }

    .user-quote-location{
        width: 60%;
        display: flex;
        gap: 5px;
        align-items:self-start;
        padding: 0 10px;

        .user-quote-pickup-location,
        .user-quote-dropoff-location{
            width: 45%;
            padding: 0 5px;
            font-size: 14px;
            font-weight: 500;

            .user-quote-date-time{
                font-size: 10px;
                font-weight: 400;
                margin-top: 5px;
            }
        }
        .user-quote-dropoff-location{
            margin-left: 5px;
        }

        .location-toggle-image{
            align-self: center;
        }

        @include respond-below(custom1024) {
            width: 70%;
        }

        @include respond-below(sm) {
            width: 100%;
        }
    }

    .user-quote-fleet{
        // width: 10%;
        background-color: #f5f5f5;
        padding: 5px;
        
        @include respond-below(custom1024) {
            display: none;
        }
    }

    @include respond-below(sm) {
        flex-direction: column;
    }
}