/*-----------------
    7. Footer
-----------------------*/

.footer {
	background: $__gray__dark;
	position: $__relative;
	&::after {
		content: "";
		background: url(../../../../public/assets/img/cars/footercar.png) no-repeat;
		position: absolute;
		bottom: 200px;
		right: 130px;
		width: 485px;
		height: 300px;
		background-size: cover;
			@include respond-below(custom1506) {
				display: none;
			}
	}
	.footer-top {
		position: relative;
		z-index: 99;
		@include margin-padding(0, 60px 0);
		@include respond-below(custom991) {  
			@include margin-padding(0, 40px 0 10px);
		}
		@include respond-below(custom767) {  
			@include margin-padding(0, 40px 0 0);
		}
		.footer-container{
			width: 85%;
			margin-left: auto;
			margin-right: auto;

			.footer-left{
				@include respond-below(custom1506){
					width: 50%;
				}

				@include respond-below(custom991){
					width: 100%;
				}
			}
			
		}
	}
	.footer-about {
		border-right: 1px solid $__black;
		@include respond-below(custom767) { 
			border: 0;
		}
	}
	.footer-widget {
		.footer-logo {
			margin-bottom: 35px;
		}
		.footer-about-content {
			margin-bottom: 50px;
			@include respond-below(custom991) {
				margin-bottom: 30px;
			}
			@include respond-below(custom767) {
				margin-bottom: 20px;
			}
			p {
				font-weight: $__bold;
				color: $__dark__black;
				margin-bottom: 0;
			}
		}
		&.footer-menu {
			ul {
				list-style: $__none;
				@include margin-padding(0, 0);
			}
		}
		.footer-title {
			position: $__relative;
			font-size: $__font__size__20;
			font-weight: $__medium;
			color: $__white;
			@include margin-padding(0 0 40px, null);
			&:before {
				content: "";
				background: $__primarycolor;
				width: 27px;
				height: 4px;
				left: 0;
				position: absolute;
				bottom: -80%;
			}
		}
		@include respond-below(custom991) {  
			margin-bottom: 30px;
		}
		@include respond-below(custom767) {  
			margin-bottom: 25px;
		}
	}
	.footer-menu {
		ul {
			li {
				@include margin-padding(0 0 20px, null);
				&:last-child {
					margin-bottom: 0;
				}
				a {
					color: $__gray_light;
					font-size: $__font__size__14;
					@include transition(all 0.5s ease-in-out);
					&:hover {
						color: $__primarycolor;
						padding-left: 10px;
						@include transition(all 0.5s ease-in-out);
					}
				}
			}
		}
	}
	.footer-contact {
		h5 {
			font-weight: 700;
			font-size: $__font__size__24;
			color: #FFFFFF;
			max-width: 336px;
			margin-bottom: 20px;
		}
		h6 {
			font-size: $__font__size__16;
			color: $__blue__light;
			margin-bottom: 15px;
			line-height: 20px;
		}
		.footer-contact-info {
			.footer-address {
				@extend %display-flex;
				margin-bottom: 20px;
				span {
					margin-right: 20px;
					width: 50px;
					height: 50px;
					background: $__primarycolor;
					color: $__white;
					font-size: $__font__size__20;
					border-radius: 4px;
					@extend %flex-align-center;
					flex-shrink: 0;
				}
				.addr-info {
					@extend %display-flex-center;
					a {	
						font-weight: 400;
						font-size: $__font__size__14;
						color: $__gray_light;
					}
				}
			}
			.update-form {
				position: relative;
				span {
					position: absolute;
					top: 50%;
					left: 10px;
					transform: translateY(-50%);
					color: $__gray__lightdark;
					font-size: $__font__size__20;
					line-height: 1;
				}
				.form-control {
					min-height: 55px;
					background: $__white;
					border-radius: 5px;
					border: 1px solid $__white;
					font-size: $__font__size__14;
					margin: 0;
					padding: 10px 100px 12px 40px;
					color: $__gray__lightdark;
				}
				.btn {
					@include margin-padding(null, 20px 20px);
					font-size: $__font__size__15;
					border-radius: 5px;
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
					.feather-send {
						color: #ffffff;
						@extend %display-flex;
					}
					&:active {
						border-color: #FCB215;
						.feather-send {
							color: #FCB215;
						}
					}
				}
			}
		}	
		
	}
	.footer-bottom {
		background: #212121;
		position: $__relative;
    	z-index: 99;
		@include margin-padding(0, 20px 0);
		@include respond-below(custom767) {  
			@include margin-padding(0, 15px 0);
		}
		.copyright {
			@include respond-below(custom767) {
				text-align: $__center;
			}
			.copyright-text {
				@include respond-below(custom767) {
					@include margin-padding(0 0 20px, null);
				}

				p {
					color: $__gray_light;	
					font-size: $__font__size__14;				
				}
			}
			.vistors-details {
				ul {
					justify-content: end;
					-webkit-justify-content: end;
					@include respond-below(custom767) { 
						justify-content: center;
					}
					li {
						@include margin-padding(0, 0 8px 0);
						a {
							background: #ffffff;
							width: 46px;
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;
						}
					}
				}
			}
		}
		.policy-menu {
		    text-align: $__right;
		    margin: 0;
		    padding: 0;
		    @include respond-below(custom767) {
				text-align: $__center;
			}
		    li {
		    	display: $__inline__block;
    			margin-right: 30px;
    			position: $__relative;
    			@include respond-below(custom767) {
					margin-right: 10px;
					margin-top: 10px;
				}
    			a {
    				color: $__black;
    				&:hover {
    					color: $__secondarycolor;
    				}
    			}
    			&::after {
				    content: "";
				    position: $__absolute;
				    background: $__secondarycolor;
				    width: 5px;
				    height: 5px;
				    left: -10px;
				    top: 50%;
				    transform: translateY(-50%);
				}
				&:last-child {
					margin-right: 0;
				}
		    }
		}
	}
}
@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
.footer-social-links {
    @include margin-padding(10px 0 30px, null);
    h6 {
        font-size: $__font__size__18;
        color: $__white;
        margin-bottom: 15px;
        @include respond-below(custom767) {
            margin-bottom: 10px;
        }
    }
    .nav {
       @extend %flex-align-center;
        li {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            a {
                &:hover {
                    color: $__black;
                }
            }
        }
    }
}

.footer-social-widget {
	@include margin-padding(20px 0 0, null);
	.nav-social {
		@extend %display-flex;
		@extend %jusflex-start;
		li {
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
			a {
				&:hover {
					color: $__black;
				}
			}
		}
	}
}
.hi-icon {
    @extend %flex-align-center;
    font-size: 0px;
    cursor: $__pointer;
    text-align: $__center;
    position: $__relative;
    z-index: 1;
    overflow: $__hidden;
    color: $__goose-gray;
    background: $__blue__light;
    border-radius: 50px;
    min-width: 36px;
    height: 36px;
    -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    @include respond-below(custom767) {
        min-width: 36px;
        height: 36px;
    }
    &:after {
        pointer-events: $__none;
        position: $__absolute;
        width: $__full__width;
        height: $__full__height;
        border-radius: 0;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        display: $__none;
    }
    &:before {
        speak: $__none;
        font-size: $__font__size__18;
        line-height: 20px;
        font-style: $__normal;
        font-weight: $__normal;
        font-variant: $__normal;
        text-transform: $__none;
        display: $__block;
        -webkit-font-smoothing: antialiased;
        @include respond-below(custom767) {
            font-size: $__font__size__15;
        }
    }
    &:hover {
        color: $__goose-gray;
        &:before {
            -webkit-animation: toTopFromBottom 0.5s forwards;
            -moz-animation: toTopFromBottom 0.5s forwards;
            animation: toTopFromBottom 0.5s forwards;
        }
    }
}
.fi-icon {
    @extend %flex-align-center;
    font-size: 0px;
    cursor: $__pointer;
    text-align: $__center;
    position: $__relative;
    z-index: 1;
    overflow: $__hidden;
    border-radius: 50px;
    min-width: 36px;
    height: 36px;
    -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    @include respond-below(custom767) {
        min-width: 36px;
        height: 36px;
    }
    &:after {
        pointer-events: $__none;
        position: $__absolute;
        width: $__full__width;
        height: $__full__height;
        border-radius: 0;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        display: $__none;
    }
    &:before {
        speak: $__none;
        font-size: $__font__size__18;
        line-height: 20px;
        font-style: $__normal;
        font-weight: $__normal;
        font-variant: $__normal;
        text-transform: $__none;
        display: $__block;
        -webkit-font-smoothing: antialiased;		
		color: $__white;
        @include respond-below(custom767) {
            font-size: $__font__size__15;
        }
    }
    &:hover {
        color: $__goose-gray;
        &:before {
            -webkit-animation: toTopFromBottom 0.5s forwards;
            -moz-animation: toTopFromBottom 0.5s forwards;
            animation: toTopFromBottom 0.5s forwards;
        }
    }
}


.subscribe-container{
	display: flex;
    height: 4rem;
    margin: 30px 0;
    /* color: #000; */
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
	border-radius: 6px;

	.subscribe_btn{
		background-color: $__primarycolor;
		border-radius: 6px;
		background-color: #0076D7;
		padding: 10px 25px;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
	}
	@include respond-below(custom1199){
		width: 90%;
	}
	@include respond-below(custom991){
		width: 100%;
	}
}