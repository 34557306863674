/*-----------------
    8. Breadcrumb
-----------------------*/

.breadcrumb-bar {
    background: $__gray__dark;
    padding: 60px 0 60px;
    position: relative;
    z-index: 1;
    width: 100%;
    @include respond-below(custom991) {
        padding: 50px 0 50px;
     } 
     .card_container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .breadcrumb-title {
        font-weight: 700;
        color: $__white;
        font-size: $__font__size__36;
        @include margin-padding(0 0 10px, null);
        @include respond-below(custom991) {
            font-size: $__font__size__32;
        } 
    }
    .breadcrumb {
        // justify-content: center;
        margin: 0;
        li {
            a {
                color: $__white;
            }
        }
        &.active {
            color: $__white;
        }
      }
    .breadcrumb-item+.breadcrumb-item {
        &::before {
            color: $__white;
        }
    }
    .breadcrumb-item.active {
        color: $__primarycolor;
    }
      
      
}

.static-location{
    display: flex;
    gap: 0.5rem;
    // color: #fff;
    h4{
        font-weight: 500;
        // color: $__white;
        font-size: $__font__size__20;
        @include margin-padding(0 0 6px, null);
        @include respond-below(custom991) {
            font-size: $__font__size__16;
        } 
    }
}


