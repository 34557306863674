/*-----------------
    9. Select
-----------------------*/

.select2-container {
    min-width: 100% !important;
    width: $__auto !important;
    z-index: 99;    
    padding: 0 !important;
    color: $__black_color;
    .select2-selection--single {
        height: 41px;
    }
}
.select2-container--default {
    .select2-selection--single {        
        background: $__light__hash;
        border: 1px solid $__light__gooses;
        @include rounded(5px);
        .select2-selection__rendered {
            color:$__gray15;
            line-height: 39px;
            @include rounded(5px);
            padding-right: 30px;
            padding-left: 15px;
            background: transparent;
        }
        .select2-selection__arrow {
            height: 40px;
            right: 6px;
        }
    }  
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    @include position($__absolute,50%,null,null,50%);
    border-color: $__gray15;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    @include transform(rotate(45deg) translateY(-50%));
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: $__gray15;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    @include margin-padding(null, 3px);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $__dark__blues;
    color: $__white;
}
.select2-container--focus  {
    .select2-selection--single{
        background: $__white !important;
        border: 1px solid $__dark__blues;
    }
}
span.select2-container.select2-container--default.select2-container--open {
    z-index: 9999;
}
