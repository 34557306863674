$__primarycolor: #0076D7;
$__primarylight: rgba(255, 255, 255, 0.2);
$__primary__light__blue: #ECEBFF;
$__secondarycolor: #201F1D;
$__secondarycolor__light: #787878;
$__white: #ffffff;
$__cylindrical: #FCFBFB;
$__grayish__blue: #3F4254;
$__gray: #2F2F2F;
$__gray__dark: #201F1D;
$__text-warning__light: #FFA633;
$__golden__yellow: #EDA600;
$__gray__lightdark: #828282;
$__light__gooses: #F4F4F4;
$__dark__blues: #127384;
$__blue__light: #DBDBDB;
$__warningcolor: #FF9307;
$__goose-gray: #201F1D;
$__sandstone: #737373;
$__light__vampire: #F2F7F6;
$__grey: #787878;
$__dangered: #FF0000;
$__note__light: rgb(130, 130, 130);
$__note__lights: rgb(130, 130, 130, 1);
$__light_grass: rgba(18, 115, 132, 1);
$__light__smokey-grays: #201F1D;
$__cloud__grays: #EDECF8;
$__graybackblue: #28283C;
$__alice__blue: #FCFCFC;
$__success__light: #1FBC2F;
$__gray15: #878A99;
$__light__grayish: #F1F1F1;
$__light__gray__has: #E3E3E3;
$__mine__shaft: #374B5C;
$__light__gray: #DEE2E7;
$__black_color:#141432;
$__black:#000;
$__white__smoke: #EFEFEF;
$__violet__dark:#377dff;
$__dark__black:#1F2937;
$__ash:#dee2e6;
$__dark__gray: #888888;
$__smoke__white: #F3F3F3;
$__bg__primary__light: #EADDFF;
$__bg__green__light: #E1FFED;
$__bg__danger__light: #FFECEC;
$__black-gray: #111111;
$__light__hash: #F5F5F5;
$__gray_light: #676767;
$__info_color: #00CCFF;
$__green__shade: #20BF8B;
$__purple: #9747FF;